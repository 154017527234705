import React from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import TwoColumn from "../../../components/two-column"
import List from "../../../components/compare-list"
import Lifecycle from "../../../components/lifecycle"
import {lifecycleContentIntegromat} from "../../../lib/lifecycle-content-integromat"

const bannerContent = {
  title: "Connect Billsby to hundreds of other applications - no dev required.",
  description: [
    {
      text: "With Integromat you can connect Billsby to more than 2,000 other applications your business uses, triggering workflows at critical points during the customer journey. It's a really powerful way to link Billsby to your business."
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today',
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: 'black',
    }
  ],
  tags: [
    // {
    //   class: 'adoric_roadmap btn-coming-soon',
    //   text: "COMING SOON",
    //   iconDisable: true,
    // }
  ],
  isBanner: true,
  sectionZapier: true,
  image: require('../../../images/zapier-switchboard.svg'),
  svg: true,
  imageName: 'developers svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const bannerContent2 = {
  title: "Chain together complex scenarios for advanced workflows.",
  description: [
    {
      text: "The main power of Integromat comes from the ability to chain together multiple actions into one scenario, which could impact literally dozens of tools and systems across your organisation."
    },
    {
      text: "It's incredible to think that you can do all of this with Billsby and Integromat, without ever having to write a single line of code."
    }
  ],
  sectionZapier: true,
  svg: false,
  image: 'chain.png',
  imageName: 'chain',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const listContent = {
  bgColor: '',
  sectionZapier: true,
  content: [
    {
      image: require('../../../images/zapier-trigger-frankenstein.svg'),
      svg: true,
      imageName: 'Zapier-Trigger-Frankenstein',
      title: "Use visual workflows to make things happen in your other apps.",
      list: [
        {
          subtitle: "Keep systems in the loop",
          text: "When a customer updates their personal details in Billsby, make that change flow through to your other systems"
        },
        {
          subtitle: "Suspend access immediately",
          text: "When customers don't pay, lock them out of your systems immediately. Then automatically reinstate access once they're back on track."
        },
        {
          subtitle: "No development required",
          text: "All of this works with no code - just create scenarios in your Integromat control panel to link Billsby to your preferred tools and services."
        },
      ]
    },
  ]
}

const listContent2 = {
  bgColor: '',
  sectionZapier: true,
  position: 'reverse',
  content: [
    {
      title: "Use scenarios to update Billsby when something happens elsewhere.",
      image: require('../../../images/plan-action-2.svg'),
      svg: true,
      imageName: 'plan-action',
      list: [
        {
          subtitle: "Update personal details",
          text: "When a customer updates their personal details elsewhere, mirror that change in Billsby."
        },
        {
          subtitle: "Change and cancel plans ",
          text: "When a customer requests a plan change in one system, reflect that change in the billing backend immediately."
        },
        {
          subtitle: "Add and remove feature tags",
          text: " Update customers access and permission levels on a granular basis by manually deploying feature tags."
        },
      ]
    },
  ]
}

const Integromat = () => {
	return (
		<Layout className="integromat">
      <SEO 
        title="Integromat" 
        description="Billsby Integromat page." 
        url="https://www.billsby.com/integromat"
      />
      <TwoColumn content={bannerContent}/>
      <List compare={listContent}/>
      <Lifecycle content={lifecycleContentIntegromat} />
      <List compare={listContent2}/>
      <TwoColumn content={bannerContent2}/>
    </Layout>
	)
}

export default Integromat
